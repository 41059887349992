// src/utils/authUtils.ts

import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { getUser } from '../services/apiService'

export const useUpdateAuthState = () => {
  const [auth, setAuth] = useRecoilState(authState)

  const updateAuthState = async () => {
    try {
      const { data: res } = await getUser()
      if (res.statusCode === 200) {
        setAuth({
          account: res.data.account,
          username: res.data.username,
          serviceCnt: res.data.serviceCnt,
          expiredAt: res.data.expiredAt,
          accountList: res.data.accountList
        })
      }
    } catch (error) {
      console.error('Failed to update auth state', error)
    }
  }

  return updateAuthState
}
