import {
  Box,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormLabel,
  useToast
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import CheckAuth from '../components/layouts/CheckAuth'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getUser, signIn } from '../services/apiService'

const SignIn = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [auth, setAuth] = useRecoilState(authState)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async () => {
    try {
      const { data: res } = await signIn({ account: email, password })
      if (res.statusCode === 200) {
        toast({
          title: '성공',
          description: res.message,
          status: 'success',
          duration: 5000,
          isClosable: true
        })

        // Navigate to a different page after login
        navigate('/app')
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const initData = async () => {
    try {
      const { data: res } = await getUser()
      if (res.statusCode === 200) {
        const user = res.data

        // DEBUG
        console.log('user => ', user)

        setAuth({
          account: user.account,
          username: user.username,
          serviceCnt: user.serviceCnt,
          expiredAt: user.expiredAt,
          accountList: user.accountList
        })

        navigate('/app')
      }
    } catch (err: any) {
      navigate('/')
    }
  }

  // ** Lifecycle
  useEffect(() => {
    initData()
  }, [])

  return (
    <>
      <DefaultLayout>
        <Box textAlign={'center'} pt={10}>
          <Text fontSize="4xl" fontWeight="bold">
            자비스
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            점프를 보다 편리하게!
          </Text>
        </Box>

        <VStack spacing={4} align="start" mt={10}>
          <FormControl id="email">
            <FormLabel>아이디</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="아이디를 입력해주세요"
              bg="gray.700"
              borderColor="gray.600"
              color="white"
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel>비밀번호</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력해주세요"
              bg="gray.700"
              borderColor="gray.600"
              color="white"
            />
          </FormControl>

          <Button
            mt={3}
            colorScheme="purple"
            onClick={handleLogin}
            width="full"
            fontSize="lg" // Larger font size
            py={6} // Increase padding (height)
            borderRadius="md" // Optionally adjust border radius
          >
            로그인
          </Button>
        </VStack>
      </DefaultLayout>
    </>
  )
}

export default SignIn
