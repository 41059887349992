// frontend/src/services/api.ts
import axios from 'axios'

export interface CommonResponse {
  statusCode: number
  message: string
  data: any
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000 // 요청 타임아웃 설정 (예: 10초)
})

export default api
