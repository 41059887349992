// ** React
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

// ** Global
import './index.css'

// ** Third
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { RecoilRoot } from 'recoil'
import { ReactFlowProvider } from 'reactflow' // ReactFlowProvider를 추가

// ** Page
import App from './pages/App'
import SignIn from './pages/SignIn'

const router = createBrowserRouter([
  {
    path: '/app',
    element: <App />
  },
  {
    path: '/',
    element: <SignIn />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RecoilRoot>
        <ReactFlowProvider>
          <RouterProvider router={router} />
        </ReactFlowProvider>
      </RecoilRoot>
    </ChakraProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
