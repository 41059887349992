// frontend/src/services/apiService.ts
import api, { CommonResponse } from './api'

// ANCHOR 로그아웃
export const signOut = async () => {
  try {
    const response = await api.delete<CommonResponse>('/api/main/signOut')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 로그인
export const signIn = async (params: object) => {
  try {
    const response = await api.post<CommonResponse>('/api/main/signIn', params)
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 세션기반사용자조회
export const getUser = async () => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getUser')
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 계정추가
interface AddAccountParams {
  scriptNo: string
  account: string
  password: string
  jumpCount: number
}
export const addAccount = async (params: AddAccountParams) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/addAccount',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 계정추가
interface SaveAccountParams {
  id: number
  scriptNo: string
  account: string
  password: string
  jumpCount: number
}
export const saveAccount = async (params: SaveAccountParams) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/saveAccount',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 계정삭제
export const deleteAccount = async (params: object) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/deleteAccount',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 태스크리스트조회
export const getTaskListForUser = async () => {
  try {
    const response = await api.get<CommonResponse>(
      '/api/main/getTaskListForUser'
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 태스크생성
interface JumpParams {
  id: number
  domainId: number
  account: string
  password: string
}
export const jump = async (params: JumpParams) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/createTaskForUser',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 타이머리스트조회
interface TimerListParams {
  id: number
}
export const getTimerList = async (params: TimerListParams) => {
  try {
    const response = await api.get<CommonResponse>('/api/main/getTimerList', {
      params
    })
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}

// ANCHOR 타이머저장
interface SaveTimerParams {
  timerList: any
  selectLinkId: number
}
export const saveTimer = async (params: SaveTimerParams) => {
  try {
    const response = await api.post<CommonResponse>(
      '/api/main/saveTimer',
      params
    )
    return response
  } catch (error) {
    console.error('Error fetching data', error)
    throw error
  }
}
