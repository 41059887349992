import { Box, Container } from '@chakra-ui/react'
import Wave from 'react-wavify'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { authState } from '../../atoms/auth'

interface DefaultLayoutProps {
  children: React.ReactNode
  showHamburgerMenu?: boolean
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const navigate = useNavigate()
  const [auth, setAuth] = useRecoilState(authState)

  // ** Lifecycle
  useEffect(() => {
    //
  }, [])

  return (
    <>
      <Box bg="black" color="white" minHeight="100vh">
        <Container maxW="768px">{children}</Container>
      </Box>
    </>
  )
}

export default DefaultLayout
