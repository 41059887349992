// src/atoms/auth.ts
import { atom } from 'recoil'

interface AuthState {
  account: string
  username: string
  serviceCnt: number
  expiredAt: string | Date | null
  accountList: Account[]
}

export interface Account {
  id: number
  userId: number
  domainId: number
  scriptNo: string
  account: string
  password: string
  jumpCount: number
}

export interface AccountList {
  accountList: Account[]
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    account: '',
    username: '',
    serviceCnt: 0,
    expiredAt: null,
    accountList: []
  }
})
