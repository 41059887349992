import { useRecoilState } from 'recoil'
import { getUser } from '../../services/apiService'
import { authState } from '../../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const CheckAuth = () => {
  const [auth, setAuth] = useRecoilState(authState)
  const navigate = useNavigate()

  // 방어 로직
  const initData = async () => {
    try {
      const { data: res } = await getUser()
      if (res.statusCode === 200) {
        const user = res.data

        // DEBUG
        console.log('user => ', user)

        setAuth({
          account: user.account,
          username: user.username,
          serviceCnt: user.serviceCnt,
          expiredAt: user.expiredAt,
          accountList: user.accountList
        })
      }
    } catch (err: any) {
      navigate('/')
    }
  }

  // ** Lifecycle
  useEffect(() => {
    initData()
  }, [])

  return <></>
}

export default CheckAuth
